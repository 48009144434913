import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as GoogleAnalytics from '../../lib/google-analytics';
import ModalError from '../ModalError';
import Spinner from '../Spinner';
import Modal from '../Modal';
import { RootState } from '../../stores';
import * as bookingApi from '../../api/booking';
import { logDataApi } from '../../lib/logDataApi';

interface CheckOutProps {
  isOpen: boolean,
  onClose: () => void,
  selectedRoom: any,
};

const CheckOut = ({
  isOpen,
  onClose,
  selectedRoom,
}: CheckOutProps) => {
  const navigation = useNavigate();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isOpenModalError, setIsOpenModalError ] = useState<boolean>(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState<string>('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState<string>('');
  const { bookingItem } = useSelector(({ booking }: RootState) => ({
    bookingItem: booking.bookingItem,
  }));

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const onOk = async () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_confirm_ok',
    });
    await checkOut();

  };

  const onCancel = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_confirm_cancel',
    });
    onClose();
  };

  const checkOut = async () => {
    try {
      setIsLoading(true);
      if (selectedRoom.roomRate !== '0') {
        setModalErrorMessage('체크아웃에 실패 하였습니다.');
        throw new Error('퇴실 잔액이 남아 있습니다. 프론트로 퇴실 처리 문의 바랍니다.');
      }
      const { data: response } = await bookingApi.checkOut({
        reservationNo: bookingItem.reservationNo,
        roomName: selectedRoom.roomName,
      });
      if (response && (response.code === 200 || response.message.toLowerCase() === 'ok')){
        logDataApi({rsvnNo: bookingItem.reservationNo, progress: '6', ref: 'Y'}); //LOG 확인
        navigation('/checkout/success', {replace: true});
      } 
      else {
        setModalErrorMessage('체크아웃에 실패 하였습니다.');
        throw new Error(`${response.status || response.code}, ${response.error || response.message}`);
      }
    } catch (error: any) {
      logDataApi({rsvnNo: bookingItem.reservationNo, progress: '6', ref: 'N'}); //LOG 확인
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_checkout_confirm',
    });
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <span className='check-out-title'>
              체크아웃을 하시겠습니까?
            </span>
            <span className='check-out-description'>
              체크아웃 시 해당 객실에 재입장 불가합니다.
            </span>
            <div className='check-out-notice-container'>
              <div className='check-out-notice-title-container'>
                <img className='notice-icon' src='../../assets/images/exclamation-mark.png' alt='' />
                <span className='check-out-notice-title'>
                  추가 서비스 결제
                </span>
              </div>
              <span className='check-out-notice-description'>
                미니바 사용 및 호텔 서비스 추가 비용이 있을
                경우 프런트바에서 현장 결제해주세요.
              </span>
            </div>
            <div className='check-out-button-container'>
              <div className='cancel-button' onClick={onCancel}>
                <span className='cancel-button-text'>
                  아니요
                </span>
              </div>
              <div className='check-out-button' onClick={onOk}>
                <span className='check-out-button-text c-red'>
                  체크아웃 하기
                </span>
              </div>
            </div>
          </>
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default CheckOut;