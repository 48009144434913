import React from 'react';
import Modal from '../Modal';

interface RoomPathProps {
  isOpen: boolean,
  onClose: () => void,
  bookingItem: any,
  selectedRoom: any,
};

const ModalRoomPath = ({
  isOpen,
  onClose,
  bookingItem,
  selectedRoom,
}: RoomPathProps) => {
  const setCondoName = () => {
    const condoName = selectedRoom.roomName?.slice(0, 2).toUpperCase();

    // C4동은 건물이 2개라 평수로 구분
    const c4CondoFormatter = (condoName: any) => {
      if (condoName === '28A') return 'C4-1';
      if (condoName === '18A') return 'C4-2';
      return condoName;
    };

    return condoName === 'C4' ? c4CondoFormatter(bookingItem.pyungType) : condoName;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <img className='w-100' src={`../../assets/images/path-${setCondoName()}.jpg`} alt='' />
            <button className='confirm-button h-40 mt-20' onClick={onClose}>
              확인
            </button>
          </>
        )}
      />
    </>
  );
};

export default ModalRoomPath;