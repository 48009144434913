import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as GoogleAnalytics from '../lib/google-analytics';
import _ from 'lodash';
import MainLayout from '../components/layouts/MainLayout';
import InitialBooking from '../components/views/BookingList';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import { RootState } from '../stores';
import { setSelectBookingQuerystringParamsAction, setBookingItemAction, setRoomInfoAction } from '../stores/booking';
import * as api from '../api/booking';
import { logDataApi } from '../lib/logDataApi';

interface urlQueryStringParams {
  rsvnNo?: string|undefined|null,
};
interface selectCheckedInBookingProps {
  reservationNo: string,
  roomName: string,
  rooms?: any,
  index?: number,
};

const BookingListContainer = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const urlQueryStringParams: urlQueryStringParams = {
    rsvnNo: searchParams.get('rsvnNo'),
  };
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isOpenModalError, setIsOpenModalError ] = useState<boolean>(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState<string>('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState<string>('');
  const { queryStringParams, bookingItem } = useSelector(({ booking }:RootState) => ({
    queryStringParams: booking.queryStringParams,
    bookingItem: booking.bookingItem,
    roomInfo: booking.roomInfo,
  }));
  const dispatch = useDispatch();

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const selectBooking = async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.selectBooking(!_.isEmpty(urlQueryStringParams) ? urlQueryStringParams : queryStringParams);
      if (response && response.error) {
        setModalErrorMessage('예약 조회에 실패 하였습니다.');
        throw new Error(`${response.status}, ${response.error}`);
      }
      if (response && response.code && response.message) {
        setModalErrorMessage('예약 조회에 실패 하였습니다.');
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (!response) {
        setModalErrorMessage('예약을 찾을 수 없습니다.');
        throw new Error('');
      }
      if (response && response.reservationNo) {
        let tempRooms = Array(parseInt(response.roomTypeCnt)).fill({});
        dispatch(setBookingItemAction({
          ...response,
          rooms: tempRooms,
        }));
        
        if(urlQueryStringParams.rsvnNo) logDataApi({rsvnNo: urlQueryStringParams.rsvnNo, progress: '0', ref: 'Y'}); //Log 체크인 확인

        return response.roomNo.map(async (name: any, index: number) => {
          const room = await selectCheckedInBooking({
            reservationNo: response.reservationNo,
            roomName: name,
            rooms: tempRooms,
            index,
          });
          tempRooms[index] = room;
        });
      }
    } catch (error: any) {
      if(urlQueryStringParams.rsvnNo) logDataApi({rsvnNo: urlQueryStringParams.rsvnNo, progress: '0', ref: 'N'}); //Log 체크인 확인

      dispatch(setBookingItemAction({}));
      dispatch(setRoomInfoAction({}));
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const selectCheckedInBooking = async ({
    reservationNo,
    roomName,
    rooms,
    index,
  }: selectCheckedInBookingProps) => {
    try {
      const { data: response } = await api.selectCheckedInBooking({
        reservationNo,
        roomName,
      });
      if (response && response.error) {
        setModalErrorMessage('투숙 조회에 실패 하였습니다.');
        throw new Error(`${response.status}, ${response.error}`);
      }
      const room = !_.isEmpty(response.data) ? _.isArray(response.data) ? response.data[0] : response.data : { reservationNo, roomName };
      if (room && room.code && room.message) {
        setModalErrorMessage('투숙 조회에 실패 하였습니다.');
        throw new Error(`${room.code}, ${room.message}`);
      }
      if (_.isNumber(index)) rooms[index] = room;
      dispatch(setRoomInfoAction(rooms));
      return room;
    } catch (error: any) {
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  };

  useEffect(() => {
    if (!_.isEmpty(urlQueryStringParams)) {
      if (searchParams.get('tokenKey')) localStorage.setItem('tokenKey', searchParams.get('tokenKey') || '');
      if (searchParams.get('bsnsCode')) localStorage.setItem('bsnsCode', searchParams.get('bsnsCode') || '');
      dispatch(setSelectBookingQuerystringParamsAction(urlQueryStringParams));
    }
    selectBooking();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingItem.reservationNo) GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_booking_info',
      label: `${bookingItem.reservationNo}_${bookingItem.reservationName}`,
    });
  }, [bookingItem.reservationName, bookingItem.reservationNo]);

  return (
    <>
      <MainLayout
        ContentBody={(
          <InitialBooking
            bookingItem={bookingItem}
            setModalErrorMessage={setModalErrorMessage}
            setModalErrorSubMessage={setModalErrorSubMessage}
            openModalError={openModalError}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default BookingListContainer;