import React from 'react';

const CustomFooter = () => {
  return (
    <>
      <div className='footer-wrapper'>
        <img className='footer-logo' src='../assets/images/footer.jpg' alt='' />
      </div>
    </>
  );
};

export default CustomFooter;