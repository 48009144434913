import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as GoogleAnalytics from '../../lib/google-analytics';
import Checkbox from '../Checkbox';
import PDF from '../PDF';

const TermsOfUseList = () => {
  const navigation = useNavigate();
  const [ isCheckedAll, setIsCheckedAll ] = useState(false);
  const [ isCheckedTermsOfUse, setIsCheckedTermsOfUse ] = useState(false);
  const [ isCheckedPrivacy, setIsCheckedPrivacy ] = useState(false);
  const [ isCheckedThirdParty, setIsCheckedThirdParty ] = useState(false);
  const [ isCheckedLocation, setIsCheckedLocation ] = useState(false);
  const [ isCheckedAge, setIsCheckedAge ] = useState(false);
  const [ isCheckedMarketing, setIsCheckedMarketing ] = useState(false);
  const [ isOpenPDFTermsOfUse, setIsOpenPDFTermsOfUse ] = useState(false);
  const [ isOpenPDFPrivacy, setIsOpenPDFPrivacy ] = useState(false);
  const isCheckedRequired = isCheckedPrivacy && isCheckedThirdParty && isCheckedLocation && isCheckedAge;

  const openPDFTermsOfUse = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_terms_of_use_details',
    });
    setIsOpenPDFTermsOfUse(true);
  };

  const closePDFTermsOfUse = () => {
    setIsOpenPDFTermsOfUse(false);
  };

  const openPDFPrivacy = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_privacy_policy_details',
    });
    setIsOpenPDFPrivacy(true);
  };

  const closePDFPrivacy = () => {
    setIsOpenPDFPrivacy(false);
  };

  const checkAll = () => {
    setIsCheckedAll(!isCheckedAll);
    setIsCheckedTermsOfUse(!isCheckedAll);
    setIsCheckedPrivacy(!isCheckedAll);
    setIsCheckedThirdParty(!isCheckedAll);
    setIsCheckedLocation(!isCheckedAll);
    setIsCheckedAge(!isCheckedAll);
    setIsCheckedMarketing(!isCheckedAll);
  };

  const onOk = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_terms_of_use_ok',
    });
    navigation('/auth');
  };

  return (
    <>
      <span className='terms-of-use-list-title'>
        약관 동의
      </span>
      <span className='terms-of-use-list-description'>
        아래 약관 내용에 동의 후 서비스 이용이 가능합니다.
      </span>
      <div className='all-agree-checkbox-container'>
        <Checkbox isChecked={isCheckedAll} onCheck={checkAll} />
        <span className='all-agree-checkbox-text'>
          전체 동의
        </span>
        <span className='all-agree-checkbox-description'>
          (선택항목 포함)
        </span>
      </div>
      <div className='horizontal-line mt-4 mb-12'></div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedTermsOfUse} onCheck={() => setIsCheckedTermsOfUse(!isCheckedTermsOfUse)} />
        <span className='agree-checkbox-text'>
          이용약관 (필수)
        </span>
        <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' onClick={openPDFTermsOfUse} />
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedPrivacy} onCheck={() => setIsCheckedPrivacy(!isCheckedPrivacy)} />
        <span className='agree-checkbox-text'>
          개인정보 수집 (필수)
        </span>
        <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' onClick={openPDFPrivacy} />
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedThirdParty} onCheck={() => setIsCheckedThirdParty(!isCheckedThirdParty)} />
        <span className='agree-checkbox-text'>
          개인정보의 제3자 제공에 관한 사항 - 회사의 국내 및 해외 체인 호텔 (필수)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' /> */}
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedLocation} onCheck={() => setIsCheckedLocation(!isCheckedLocation)} />
        <span className='agree-checkbox-text'>
          위치기반서비스 이용약관 (필수)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' /> */}
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedAge} onCheck={() => setIsCheckedAge(!isCheckedAge)} />
        <span className='agree-checkbox-text'>
          만 14세 이상 이용 동의 (필수)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' /> */}
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedMarketing} onCheck={() => setIsCheckedMarketing(!isCheckedMarketing)} />
        <span className='agree-checkbox-text'>
          이벤트 및 마케팅 활용 동의 (선택)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' onClick={openModalPDFAgreement} /> */}
      </div>
      <button className='confirm-button mt-22' disabled={!isCheckedRequired} onClick={onOk}>
        확인
      </button>
      <PDF
        srcName='https://s3.ap-northeast-2.amazonaws.com/multifamily.imgate/OVAL+MMS+%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%80%E1%85%AA%E1%86%AB_20220614.pdf'
        title='이용약관'
        isOpen={isOpenPDFTermsOfUse}
        onClose={closePDFTermsOfUse}
      />
      <PDF
        srcName='https://s3.ap-northeast-2.amazonaws.com/multifamily.imgate/OVAL+MMS+%E1%84%80%E1%85%A2%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A9+%E1%84%89%E1%85%AE%E1%84%8C%E1%85%B5%E1%86%B8%C2%B7%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%AD%E1%86%BC+%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B4_20220615.pdf'
        title='개인정보처리방침'
        isOpen={isOpenPDFPrivacy}
        onClose={closePDFPrivacy}
      />
    </>
  );
};

export default TermsOfUseList;