import { request, headers, querystring } from './lib/common';
import { selectBookingProps, selectCheckedInBookingProps, checkInProps, checkOutProps } from './types/booking';

export const selectBooking = ({rsvnNo:reservationNo}: selectBookingProps) => {
  return request.get(`/reservations/${reservationNo}`, {
    ...headers({}),
    ...querystring({
      tokenKey: localStorage.getItem('tokenKey'),
    }),
  });
};

export const selectCheckedInBooking = ({reservationNo, roomName}: selectCheckedInBookingProps) => {
  return request.get(`/inhouses/${reservationNo}`, {
    ...headers({}),
    ...querystring({
      tokenKey: localStorage.getItem('tokenKey'),
      roomName,
    }),
  });
};

export const checkIn = ({reservationNo, roomName}: checkInProps) => {
  return request.post(`/checkin`,
    null,
    {
      ...headers({}),
      ...querystring({
        tokenKey: localStorage.getItem('tokenKey'),
        reservationNo,
        roomName,
      }),
    },
  );
};

export const checkOut = ({reservationNo, roomName}: checkOutProps) => {
  return request.post(`/checkout`,
    null,
    {
      ...headers({}),
      ...querystring({
        tokenKey: localStorage.getItem('tokenKey'),
        reservationNo,
        roomName,
      }),
    },
  );
};
