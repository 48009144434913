import React from 'react';

const CheckOutPaymentSuccess = () => {
  return (
    <>
      <div className='check-out-success-info-container'>
        <img className='check-out-success ml-10' src='../../assets/images/check-out-success.jpg' alt='' />
        <span className='check-out-success-title'>
          CHECK OUT
        </span>
        <span className='check-out-success-description'>
          체크아웃이 완료 되었습니다.
        </span>
        <span className='check-out-success-sub-description'>
          안전하고 편안한 귀갓길 되시기 바랍니다.<br/>
          항상 보다 나은 서비스로 고객님의 성원에 보답하겠습니다. 감사합니다.
        </span>
      </div>
    </>
  );
};

export default CheckOutPaymentSuccess;