import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import * as GoogleAnalytics from '../../lib/google-analytics';
import LocationService from './LocationService';
import RoomPath from './RoomPath';
import PincodeMenual from './PincodeMenual';
import CheckOutRequest from './CheckOutRequest';
import { logDataApi } from '../../lib/logDataApi';

interface InitialBookingProps {
  bookingItem: any,
  setModalErrorMessage: (state: string) => void,
  setModalErrorSubMessage: (state: string) => void,
  openModalError: () => void,
};
interface roomInfoProps {
  roomtypeName?: string,
  roomName?: string,
  bedtypeName?: string|null,
  floorName?: string|null,
  viewtypeName?: string|null,
};
interface validateCheckIn {
  checkinDate: string,
  checkinTime: string,
  checkoutDate: string,
};
interface openModalRoomPathProps {
  room: roomInfoProps,
}
interface openModalCheckoutProps {
  room: roomInfoProps,
}
interface periodFormatterProps {
  checkinDate: string,
  checkoutDate: string,
};
interface dayFormatterProps {
  day: string,
};
interface setBookingDateProps {
  date: string,
};
interface setBookingTimeProps {
  time: string,
};

const InitialBooking = ({
  bookingItem,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
}: InitialBookingProps) => {
  const navigation = useNavigate();
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  const [ isOpenModalLocationService, setIsOpenModalLocationService ] = useState<boolean>(false);
  const [ isOpenModalRoomPath, setIsOpenModalRoomPath ] = useState<boolean>(false);
  const [ isOpenModalPincodeMenual, setIsOpenModalPincodeMenual ] = useState<boolean>(false);
  const [ isOpenModalCheckOut, setIsOpenModalCheckOut ] = useState<boolean>(false);
  const [ selectedRoom, setSelectedRoom ] = useState<roomInfoProps>({});

  const openModalLocationService = useCallback(() => {
    
    if(bookingItem.reservationNo) logDataApi({rsvnNo: bookingItem.reservationNo, progress: '2', ref: 'Y'}); //Log 체크인 확인

    setIsOpenModalLocationService(true);
  }, [bookingItem.reservationNo]);

  const closeModalLocationService = useCallback(() => {
    setIsOpenModalLocationService(false);
  }, []);

  const openModalRoomPath = useCallback(({
    room,
  }: openModalRoomPathProps) => {
    GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_room_path',
    });
    setSelectedRoom(room);
    setIsOpenModalRoomPath(true);
  }, []);

  const closeModalRoomPath = useCallback(() => {
    setIsOpenModalRoomPath(false);
  }, []);

  const openModalPincodeMenual = useCallback(() => {
    setIsOpenModalPincodeMenual(true);
  }, []);

  const closeModalPincodeMenual = useCallback(() => {
    setIsOpenModalPincodeMenual(false);
  }, []);

  const openModalCheckout = useCallback(({
    room,
  }: openModalCheckoutProps) => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_request',
    });
    if(bookingItem.reservationNo) logDataApi({rsvnNo: bookingItem.reservationNo, progress: '5', ref: 'Y'}); //Log 체크인 확인
    setSelectedRoom(room);
    setIsOpenModalCheckOut(true);
  }, [bookingItem.reservationNo]);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const validateCheckIn = ({checkinDate, checkinTime, checkoutDate}: validateCheckIn) => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_request',
    });

    if (isTestMode) navigation('/terms_of_use');
    const availableCheckinDate = `${checkinDate}T${checkinTime || '1500'}`;
    const availableCheckoutDate = `${checkoutDate}T1100`;
    if (moment(availableCheckoutDate) < moment()) {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage('만료된 예약 입니다.');
      openModalError();
    }
    else if (moment(availableCheckinDate) > moment()) {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage(`체크인은 ${moment(availableCheckinDate).format('A') === 'AM' ? '오전':'오후'} ${moment(availableCheckinDate).format('h')}시 이후 부터 가능 합니다.`);
      openModalError();
    }
    else openModalLocationService();
  };

  const periodFormatter = ({checkinDate, checkoutDate}: periodFormatterProps) => {
    return `${moment(checkinDate).format('YYYY.MM.DD')} ~ ${moment(checkoutDate).format('YYYY.MM.DD')}`
  };

  const dayFormatter = ({day}: dayFormatterProps) => {
    return {
      Sun: '일',
      Mon: '월',
      Tue: '화',
      Wed: '수',
      Thu: '목',
      Fri: '금',
      Sat: '토',
    }[day] || day;
  };

  const setBookingDate = ({date}: setBookingDateProps) => {
    return `${moment(date).format('YYYY.MM.DD')} (${dayFormatter({day: moment(date).format('ddd')})})`;
  };

  const setBookingTime = ({time}: setBookingTimeProps) => {
    return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  };

  const dataParserByStatus = () => {
    // 예약이 없는 경우
    if (!bookingItem || !bookingItem.reservationNo)
      return (
        <div className='initial-booking-card-container'>
          <div className='warning-icon'>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
          <span className='warning-message'>예약을 찾을 수 없습니다.</span>
        </div>
      )
    switch (bookingItem.status) {
      // 예약이 취소된 경우
      case 'CC':
        return (
          <div className='initial-booking-card-container'>
            <div className='warning-icon'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <span className='warning-message'>취소된 예약 입니다.</span>
            <div className='horizontal-line mt-12 mb-10'></div>
            <span className='booking-period'>
              {periodFormatter({
                checkinDate: bookingItem.checkinDate,
                checkoutDate: bookingItem.checkoutDate,
              })}
            </span>
            <span className='hotel-name'>
              {`${parseInt(bookingItem.checkoutDate) - parseInt(bookingItem.checkinDate)}박, ${bookingItem.pyungType}평형`}
            </span>
            <div className='user-info-container'>
              <img className='user-icon' src='../../assets/images/profile.png' alt='' />
              <span className='user-name'>
                {bookingItem.reservationName}
              </span>
              <span className='booking-number'>
                {bookingItem.reservationNo}
              </span>
            </div>
            <div className='check-in-button disabled'>
              <span className='check-in-button-text'>
                체크인 하기
              </span>
            </div>
          </div>
        )
      // 체크아웃된 경우
      case 'CO':
        // if (_.isEmpty(bookingItem.roomNo) && bookingItem.roomTypeCnt === '0')
        return (
          <div className='initial-booking-card-container'>
            <div className='warning-icon'>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <span className='warning-message'>체크아웃된 예약 입니다.</span>
            <div className='horizontal-line mt-12 mb-10'></div>
            <span className='booking-period'>
              {periodFormatter({
                checkinDate: bookingItem.checkinDate,
                checkoutDate: bookingItem.checkoutDate,
              })}
            </span>
            <span className='hotel-name'>
              {`${parseInt(bookingItem.checkoutDate) - parseInt(bookingItem.checkinDate)}박, ${bookingItem.pyungType}평형`}
            </span>
            <div className='user-info-container'>
              <img className='user-icon' src='../../assets/images/profile.png' alt='' />
              <span className='user-name'>
                {bookingItem.reservationName}
              </span>
              <span className='booking-number'>
                {bookingItem.reservationNo}
              </span>
            </div>
            <div className='check-in-button disabled'>
              <span className='check-in-button-text'>
                체크인 하기
              </span>
            </div>
          </div>
        );
      default:
        return (
          // 체크인 전
          bookingItem.rooms.map((assignedRoom: any, index: number) => _.isEmpty(assignedRoom) ? (
            <div className='initial-booking-card-container' key={index}>
              <div className='hotel-img-container'></div>
              <span className='booking-period'>
                {periodFormatter({
                  checkinDate: bookingItem.checkinDate,
                  checkoutDate: bookingItem.checkoutDate,
                })}
              </span>
              <span className='hotel-name'>
                {`${parseInt(bookingItem.checkoutDate) - parseInt(bookingItem.checkinDate)}박, ${bookingItem.pyungType}평형`}
              </span>
              <div className='user-info-container'>
                <img className='user-icon' src='../../assets/images/profile.png' alt='' />
                <span className='user-name'>
                  {bookingItem.reservationName}
                </span>
                <span className='booking-number'>
                  {bookingItem.reservationNo}
                </span>
              </div>
              <div
                className='check-in-button'
                onClick={() => validateCheckIn({
                  checkinDate: bookingItem.checkinDate,
                  checkinTime: bookingItem.checkinTime,
                  checkoutDate: bookingItem.checkoutDate,
                })}
              >
                <span className='check-in-button-text'>
                  체크인 하기
                </span>
              </div>
            </div>
          ) : (
            // 체크인 후
            <>
              <div className='initial-booking-card-container' key={index}>
                <div className='hotel-img-container'></div>
                <div className='pl-20 pr-20'>
                  <span className='hotel-name m-0 ta-center'>
                    {bookingItem.buildingName || '-'}
                  </span>
                  <span className='room-name'>
                    {assignedRoom.roomName ? `${assignedRoom.roomName.slice(0, 2)}동 ${assignedRoom.roomName.slice(2)}` : '-'}
                  </span>
                  <div
                    className='room-path-button'
                    onClick={() => openModalRoomPath({
                      room: assignedRoom,
                    })}
                  >
                    <span className='room-path-button-text'>
                      객실위치 보기
                    </span>
                  </div>
                  <div className='horizontal-line mt-12 mb-10'></div>
                  <div className='userkey-container'>
                    <div className='userkey-item'>
                      <span className='userkey-item-label'>
                        객실 비밀번호
                      </span>
                      <span className='userkey-item-value m-0'>
                        {assignedRoom.pincode ? `${assignedRoom.pincode}*` : '-'}
                      </span>
                    </div>
                  </div>
                  <div
                    className='room-path-button'
                    onClick={() => openModalPincodeMenual()}
                  >
                    <span className='room-path-button-text'>
                      사용방법
                    </span>
                  </div>
                  <div className='horizontal-line mt-12 mb-12'></div>
                  <div className='checked-in-booking-period-container'>
                    <img className='period-icon mt-2' src='../../assets/images/period.png' alt='' />
                    <div className='period-item-container'>
                      <div className='period-item mb-8'>
                        <span className='period-item-label'>
                          체크인
                        </span>
                        <span className='period-item-value'>
                          {bookingItem.checkinDate
                          ? `${setBookingDate({date: bookingItem.checkinDate})} ${bookingItem.checkinTime && bookingItem.checkinTime.length === 4
                            ? setBookingTime({time: bookingItem.checkinTime})
                            :'15:00'}`
                          : '-'}
                        </span>
                      </div>
                      <div className='period-item'>
                        <span className='period-item-label'>
                          체크아웃
                        </span>
                        <span className='period-item-value'>
                          {bookingItem.checkoutDate
                          ? `${setBookingDate({date: bookingItem.checkoutDate})} ${bookingItem.checkoutTime && bookingItem.checkoutTime.length === 4
                            ? setBookingTime({time: bookingItem.checkoutTime})
                            :'11:00'}`
                          : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className='check-in-button ml-0 mr-0'
                    onClick={() => openModalCheckout({
                      room: assignedRoom,
                    })}
                  >
                    <span className='check-in-button-text'>
                      체크아웃 하기
                    </span>
                  </div>
                </div>
              </div>
              {_.isEmpty(bookingItem.productList)
              ? ''
              : (
                <div className='service-list-container'>
                  <span className='service-list-title'>부대업장 예약현황</span>
                  <table className='service-list-table'>
                    <thead>
                      <tr>
                        <th>이용일자</th>
                        <th>영업장</th>
                        <th>메뉴</th>
                        <th>수량</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingItem.productList.map((product: any, index: number) => (
                        <tr>
                          <td>{product.calnDate ? moment(product.calnDate).format('YYYY.MM.DD') : '-'}</td>
                          <td>{product.facilityName || '-'}</td>
                          <td>{product.productGbnName || '-'}</td>
                          <td>{product.saleQty}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          ))
        );
    }
  }

  return (
    <>
      <span className='initial-booking-welcome-message'>
        편하고 안전한<br/>
        체크인 서비스로<br/>
        여행을 시작하세요
      </span>
      {dataParserByStatus()}
      <LocationService
        isOpen={isOpenModalLocationService}
        onClose={closeModalLocationService}
      />
      <RoomPath
        isOpen={isOpenModalRoomPath}
        onClose={closeModalRoomPath}
        bookingItem={bookingItem}
        selectedRoom={selectedRoom}
      />
      <PincodeMenual
        isOpen={isOpenModalPincodeMenual}
        onClose={closeModalPincodeMenual}
      />
      <CheckOutRequest
        isOpen={isOpenModalCheckOut}
        onClose={closeModalCheckOut}
        selectedRoom={selectedRoom}
      />
    </>
  );
};

export default InitialBooking;