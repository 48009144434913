import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import CheckInRequest from './CheckInRequest';
import * as GoogleAnalytics from '../../lib/google-analytics';

interface RoomListProps {
  bookingItem: any,
  availableCondoList: condoInfoProps[],
  availableFloorList: floorInfoProps[],
  availableRoomList: roomInfoProps[],
  selectedRoom: roomInfoProps,
  changeCondo: (condo: condoInfoProps) => void,
  changeFloor: (floor: floorInfoProps) => void, 
  changeRoom: (room: roomInfoProps) => void,
};
interface condoInfoProps {
  name?: string,
  status?: string,
}
interface floorInfoProps {
  name?: string,
  status?: string,
}
interface roomInfoProps {
  roomtypeName?: string,
  roomName?: string,
  bedtypeName?: string|null,
  floorName?: string|null,
  viewtypeName?: string|null,
  status?: string|null,
};

const RoomList = ({
  bookingItem,
  availableCondoList,
  availableFloorList,
  availableRoomList,
  selectedRoom,
  changeCondo,
  changeFloor,
  changeRoom,
}: RoomListProps) => {
  const [ isOpenModalCheckIn, setIsOpenModalCheckIn ] = useState<boolean>(false);

  const openModalCheckIn = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_request',
    });
    setIsOpenModalCheckIn(true);
  }, []);

  const closeModalCheckIn = useCallback(() => {
    setIsOpenModalCheckIn(false);
  }, []);

  const setCondoName = () => {
    const condoName = selectedRoom.roomName?.slice(0, 2).toUpperCase();

    // C4동은 건물이 2개라 평수로 구분
    const c4CondoFormatter: any = {
      '28A': 'C4-1',
      '18A': 'C4-2',
    };

    return condoName === 'C4' ? c4CondoFormatter[bookingItem.pyungType] : condoName;
  };

  return (
    <>
      <div className='ml-30 mr-30'>
        <span className='verification-title'>
          객실 선택
        </span>
        <span className='verification-description mb-20'>
          원하시는 객실을 선택해주세요.
        </span>
      </div>
      {!_.isEmpty(availableCondoList) ? (
        <div>
          <div className='p-relative'>
            <img className='condo-map' src={`../../assets/images/${setCondoName()}.jpg`} alt='' />
          </div>
          <div className='selected-room-info-container'>
            <span className='selected-condo-name'>
              {`${bookingItem.buildingName} ${selectedRoom.roomName?.slice(0, 2)}동`}
            </span>
            <span className='selected-room-name'>
              {`${selectedRoom.roomName?.slice(2, 3)}F ${selectedRoom.roomName?.slice(2)}`}
            </span>
          </div>
          <span className='building-list-title'>
            동
          </span>
          <div className='building-list-container'>
            {availableCondoList.map((condo: condoInfoProps, index) => (
              <span
                key={index}
                className={`building-name ${condo.status}`}
                onClick={() => changeCondo(condo)}
              >
                {condo.name}
              </span>
            ))}
          </div>
          <span className='floor-list-title'>
            층
          </span>
          <div className='floor-list-container'>
            {availableFloorList.map((floor: floorInfoProps, index) => (
              <span
                key={index}
                className={`floor-name ${floor.status}`}
                onClick={() => changeFloor(floor)}
              >
                {`${floor.name}F`}
              </span>
            ))}
          </div>
          <span className='room-list-title'>
            {`${selectedRoom.roomName?.slice(2, 3)}F`}
          </span>
          {availableRoomList.map((room: roomInfoProps, index) => (
            <>
              <div key={index} className='room-list-container'>
                <span
                  className={`room-name ${room.status}`}
                  onClick={() => changeRoom(room)}
                >
                  {room.roomName?.slice(2)}
                </span>
                <div className='bed-type-container'>
                  <span className='bed-type-name'>
                    {room.bedtypeName || '침대타입 없음'}
                  </span>
                </div>
              </div>
            </>
          ))}
          <div className='room-confirm-button-container'>
            <button className='room-confirm-button' onClick={openModalCheckIn} disabled={_.isEmpty(selectedRoom)}>
              체크인 하기
            </button>
          </div>
        </div>
      ) : (
        <div className='ml-30 mr-30'>
          <div className='warning-icon'>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </div>
          <span className='warning-message'>사용 가능한 객실이 없습니다.</span>
        </div>
      )}
      <CheckInRequest isOpen={isOpenModalCheckIn} onClose={closeModalCheckIn} selectedRoom={selectedRoom} />
    </>
  );
};

export default RoomList;