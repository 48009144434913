import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import { createRequestSaga, createRequestActionTypes } from './lib/createRequestSaga';
import * as BookingAPI from '../api/booking';
import { bookingSelectState, queryStringProps, bookingItemProps, roomInfoProps, userInfoProps } from './types/booking';

const INITIALIZE = 'booking/INITIALIZE';
const SET_SELECT_BOOKING_QUERYSTRING_PARAMS = 'booking/SET_SELECT_BOOKING_QUERYSTRING_PARAMS';
const SET_BOOKING_ITEM = 'booking/SET_BOOKING_ITEM';
const SET_ROOM_INFO = 'booking/SET_ROOM_INFO';
const SET_USER_INFO = 'booking/SET_USER_INFO';

//리덕스 모듈에서 API를 사용할 수 있게 추가
const [BOOKING_SELECT, BOOKING_SELECT_SUCCESS, BOOKING_SELECT_FAILURE] = createRequestActionTypes('booking/BOOKING_SELECT');

/* action */
export const initialize = createAction(INITIALIZE);
export const setSelectBookingQuerystringParamsAction = createAction(SET_SELECT_BOOKING_QUERYSTRING_PARAMS, (queryStringParams:queryStringProps) => ({
  queryStringParams,
}));
export const setBookingItemAction = createAction(SET_BOOKING_ITEM, (bookingItem:bookingItemProps) => ({
  bookingItem,
}));
export const setRoomInfoAction = createAction(SET_ROOM_INFO, (roomInfo:roomInfoProps) => ({
  roomInfo,
}));
export const setUserInfoAction = createAction(SET_USER_INFO, (userInfo:userInfoProps) => ({
  userInfo,
}));

//사가 생성
const selectBookingSaga = createRequestSaga(BOOKING_SELECT, BookingAPI.selectBooking);

export function* bookingSaga(){
  yield takeLatest(BOOKING_SELECT, selectBookingSaga);
}

const initialState:bookingSelectState = {
  queryStringParams: {},
  bookingItem: {
    reservationNo: '',
    status: '',
    reservationName: '',
    checkinDate: '',
    checkoutDate: '',
    propertyName: '',
    checkinTime: '',
    roomtypeCnt: 0,
    roomName: [],
    rooms: [],
  },
  roomInfo: {},
  userInfo: {},
  bookingSelectError: null,
};

export const bookingReducer = handleActions<bookingSelectState, any>(
  {
    [INITIALIZE] : state => initialState, // initialState를 넣으면 초기 상태로 바뀜
    // 예약 상세 조회 성공
    [BOOKING_SELECT_SUCCESS] : (state, { payload: bookingItem }) => ({
      ...state,
      bookingItem,
      bookingSelectError: null,
    }),
    // 예약 상세 조회 실패
    [BOOKING_SELECT_FAILURE] : (state, { payload: error }) => ({
      ...state,
      bookingSelectError: error,
    }),
    [SET_SELECT_BOOKING_QUERYSTRING_PARAMS] : (state, { payload: { queryStringParams } }) => ({
      ...state,
      queryStringParams,
    }),
    [SET_BOOKING_ITEM] : (state, { payload: { bookingItem } }) => ({
      ...state,
      bookingItem,
    }),
    [SET_ROOM_INFO] : (state, { payload: { roomInfo } }) => ({
      ...state,
      bookingItem: {
        ...state.bookingItem,
        rooms: roomInfo,
      },
    }),
    // [SET_ROOM_INFO] : (state, { payload: roomInfo }) => ({
    //   ...state,
    //   roomInfo,
    // }),
    [SET_USER_INFO] : (state, { payload: userInfo }) => ({
      ...state,
      userInfo,
    }),
  },
  initialState,
);
