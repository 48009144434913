import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as GoogleAnalytics from '../lib/google-analytics';
import { RootState } from '../stores';

const KaKaoAuthSuccessContainer = () => {
  const navigation = useNavigate();
  const { bookingItem } = useSelector(({ booking }: RootState) => ({
    bookingItem: booking.bookingItem,
  }));

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_kakao_auth_success',
      label: `${bookingItem.reservationNo}_${bookingItem.status}_${bookingItem.reservationName}`,
    });
    navigation('/room', {replace: true});
  }, [bookingItem.reservationName, bookingItem.reservationNo, bookingItem.status, navigation]);

  return (
    <></>
  );
};

export default KaKaoAuthSuccessContainer;