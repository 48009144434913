import React, { useEffect } from 'react';
import * as GoogleAnalytics from '../lib/google-analytics';
import MainLayout from '../components/layouts/MainLayout';
import TermsOfUseList from '../components/views/TermsOfUseList';

const TermsOfUseListContainer = () => {
  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_terms_of_use',
    });
  }, []);

  return (
    <>
      <MainLayout
        ContentBody={(
          <TermsOfUseList />
        )}
      />
    </>
  );
};

export default TermsOfUseListContainer;