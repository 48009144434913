import axios from 'axios';
import { CommonHeaderProps, CommonQueryProps } from '../types/commons';

const baseURL = process.env.REACT_APP_ELDORADO_BACKEND_BASEURL;
const apiKey = 'tjgyekrkaksemfdjTek';

// config
export const request = axios.create({
  baseURL,
  timeout: 30000,
});

export const headers = (header: CommonHeaderProps) => {
  return {
    headers: {
      'Authorization': apiKey,
      'Content-Type': 'application/json',
      ...header && header,
    }
  };
};

export const querystring = (query: CommonQueryProps) => {
  return {
    params: query,
  };
};