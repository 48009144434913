import React from 'react';

interface CheckboxProps {
  isChecked: boolean,
  onCheck: () => void,
};

const Checkbox = ({
  isChecked,
  onCheck,
}: CheckboxProps) => {
  const setClassName = `agree-checkbox ${isChecked ? 'checked' : ''}`;
  const setImg = `../assets/images/${isChecked ? 'checked' : 'unchecked'}.png`;

  return (
    <>
      <div className={setClassName} onClick={onCheck}>
        <img className='checkbox' src={setImg} alt='' />
      </div>
    </>
  );
};

export default Checkbox;