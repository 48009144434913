import { joinKakaoProps } from './types/roomio';

import axios from 'axios';
import { CommonHeaderProps } from './types/commons';

const baseURL = process.env.REACT_APP_ROOMIO_BACKEND_BASEURL;
const apiKey = '3ff5b1a8-11c8-4e70-b725-80ac0b667a0e';

// config
export const request = axios.create({
  baseURL,
  timeout: 30000,
});

export const headers = (header: CommonHeaderProps) => {
  return {
    headers: {
      'Authorization': `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
      ...header && header,
    }
  };
};

export const joinKakao = ({ access_token }: joinKakaoProps) => {
  return request.post(`/member/join/${access_token}`,
    {},
    headers({}),
  );
};
