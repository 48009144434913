import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as GoogleAnalytics from '../../lib/google-analytics';
import ModalError from '../ModalError';
import Spinner from '../Spinner';
import Modal from '../Modal';
import { RootState } from '../../stores';
import * as bookingApi from '../../api/booking';
import { logDataApi } from '../../lib/logDataApi';

interface CheckInProps {
  isOpen: boolean,
  onClose: () => void,
  selectedRoom: any,
};

const CheckIn = ({
  isOpen,
  onClose,
  selectedRoom,
}: CheckInProps) => {
  const navigation = useNavigate();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isOpenModalError, setIsOpenModalError ] = useState<boolean>(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState<string>('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState<string>('');
  const { bookingItem } = useSelector(({ booking }: RootState) => ({
    bookingItem: booking.bookingItem,
  }));

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const onOk = async () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_confirm_ok',
    });
    await checkIn();
  };

  const onCancel = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_confirm_cancel',
    });
    onClose();
  };

  const checkIn = async () => {
    try {
      setIsLoading(true);
      const { data: response } = await bookingApi.checkIn({
        reservationNo: bookingItem.reservationNo,
        roomName: selectedRoom.roomName,
      });
      if (response && response.code === 200) {
        // rsvnNo, tokenKey는 필수, bsnsCode는 옵션
        logDataApi({rsvnNo: bookingItem.reservationNo, progress: '3', ref: 'Y'}); //LOG 확인
        navigation(`/?rsvnNo=${bookingItem.reservationNo}&tokenKey=${localStorage.getItem('tokenKey')}${localStorage.getItem('bsnsCode') ? `&bsnsCode=${localStorage.getItem('bsnsCode')}` : ''}`, {replace: true});
      }
      else {
        setModalErrorMessage('체크인에 실패 하였습니다.');
        throw new Error(`${response.status || response.code}, ${response.error || response.message}`);
      }
    } catch (error: any) {
      logDataApi({rsvnNo: bookingItem.reservationNo, progress: '3', ref: 'N'}); //LOG 확인
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_checkin_confirm',
    });
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <span className='check-in-request-title'>
              체크인을 하시겠습니까?
            </span>
            <span className='check-in-request-description'>
              선택하신 객실 정보를 확인해주세요.
            </span>
            <div className='check-in-request-notice-container'>
              <div className='check-in-request-notice-title-container'>
                <span className='check-in-request-notice-title'>
                  {`${bookingItem.buildingName} ${selectedRoom.roomName?.slice(0, 2)}동`}
                </span>
                <span className='check-in-request-notice-title-import'>
                  {`${selectedRoom.roomName?.slice(2, 3)}F ${selectedRoom.roomName?.slice(2)}`}
                </span>
              </div>
            </div>
            <div className='check-in-request-button-container'>
              <div className='cancel-button' onClick={onCancel}>
                <span className='cancel-button-text'>
                  아니요
                </span>
              </div>
              <div className='check-in-request-button' onClick={onOk}>
                <span className='check-in-request-button-text c-blue'>
                  체크인 하기
                </span>
              </div>
            </div>
          </>
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default CheckIn;