import React from 'react';
import Header from '../Header';
import Content from '../Content';
import Footer from '../Footer';

interface MainLayoutProps {
  customStyle?: any,
  ContentBody: any,
  isHiddenFooter?: boolean,
};

const MainLayout = ({
  customStyle,
  ContentBody,
  isHiddenFooter,
}: MainLayoutProps) => {
  return (
    <>
      <Header />
      <Content customStyle={customStyle} ContentBody={ContentBody}/>
      {isHiddenFooter ? '' : (
        <Footer />
      )}
    </>
  );
};

export default MainLayout;