import React from 'react';

interface CustomContentProps {
  ContentBody: any,
  customStyle?: any,
};

const CustomContent = ({
  ContentBody,
  customStyle,
}: CustomContentProps) => {
  return (
    <>
      <div className='content-container' style={customStyle}>
        {ContentBody}
      </div>
    </>
  );
};

export default CustomContent;