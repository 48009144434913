import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as GoogleAnalytics from '../lib/google-analytics';
import MainLayout from '../components/layouts/MainLayout';
import CheckOutSuccess from '../components/views/CheckOutSuccess';
import Spinner from '../components/Spinner';
import { RootState } from '../stores';

const CheckOutSuccessContainer = () => {
  const navigation = useNavigate();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const { bookingItem } = useSelector(({ booking }: RootState) => ({
    bookingItem: booking.bookingItem,
  }));

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkout_success',
    });
    setTimeout(() => setIsLoading(true), 1500);
    setTimeout(() => {
      setIsLoading(false);
      // rsvnNo, tokenKey는 필수, bsnsCode는 옵션
      navigation(`/?rsvnNo=${bookingItem.reservationNo}&tokenKey=${localStorage.getItem('tokenKey')}${localStorage.getItem('bsnsCode') ? `&bsnsCode=${localStorage.getItem('bsnsCode')}` : ''}`, {replace: true});
    }, 3000);
  }, [bookingItem.reservationNo, navigation]);

  return (
    <>
      <MainLayout
        ContentBody={(
          <CheckOutSuccess />
        )}
        isHiddenFooter={true}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default CheckOutSuccessContainer;