import { request, headers, querystring } from './lib/common';
import { listRoomProps } from './types/room';

export const listRoom = ({rsvnNo:reservationNo}: listRoomProps) => {
  return request.get(`/rooms/${reservationNo}`, {
    ...headers({}),
    ...querystring({
      tokenKey: localStorage.getItem('tokenKey'),
    }),
  });
};
