import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as GoogleAnalytics from '../lib/google-analytics';
import MainLayout from '../components/layouts/MainLayout';
import RoomList from '../components/views/RoomList';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import { RootState } from '../stores';
import * as roomApi from '../api/room';

interface condoInfoProps {
  name?: string,
  status?: string,
}
interface floorInfoProps {
  name?: string,
  status?: string,
}
interface roomInfoProps {
  roomtypeName?: string,
  roomName?: string,
  bedtypeName?: string|null,
  floorName?: string|null,
  viewtypeName?: string|null,
  status?: string|null,
};

const RoomListContainer = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isOpenModalError, setIsOpenModalError ] = useState<boolean>(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState<string>('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState<string>('');
  const [ totalRoomList, setTotalRoomList ] = useState<object[]>([]);
  const [ availableCondoList, setAvailableCondoList ] = useState<object[]>([]);
  const [ availableFloorList, setAvailableFloorList ] = useState<object[]>([]);
  const [ availableRoomList, setAvailableRoomList ] = useState<object[]>([]);
  const [ selectedRoom, setSelectedRoom ] = useState<roomInfoProps>({});
  const { bookingItem } = useSelector(({ booking }: RootState) => ({
    bookingItem: booking.bookingItem,
  }));

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const listRoom = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await roomApi.listRoom({rsvnNo: bookingItem.reservationNo});
      if (response && response.error) {
        setModalErrorMessage('사용 가능 객실 목록 조회에 실패 하였습니다.');
        throw new Error(`${response.status}, ${response.error}`);
      }
      if (response && !response.data.length) {
        setModalErrorMessage('사용 가능 객실이 없습니다.');
        throw new Error('');
      }
      if (response && response.data.length && response.data[0].roomName) {
        // 총 객실 목록 저장
        const sortedRoomList = _.sortBy(response.data, ['roomName']);
        setTotalRoomList(sortedRoomList);

        // 임의의 객실 선택: 객실 목록 중, 첫번째 객실
        setSelectedRoom({ ...sortedRoomList[0], status: 'selected' });
        // 선택된 객실의 동 이름, 층 이름, 객실 이름
        const selectedCondoName: string = sortedRoomList[0].roomName.slice(0, 2);
        const selectedFloorName: string = sortedRoomList[0].roomName.slice(2, 3);
        const selectedRoomName: string = sortedRoomList[0].roomName;

        // 사용 가능한 동, 층, 객실 목록 설정
        let tempAvailableCondoList: object[] = [];
        let tempAvailableFloorList: object[] = [];
        let tempAvailableRoomList: object[] = [];
        sortedRoomList.map((room: any) => {
          const condoName: string = room.roomName.slice(0, 2);
          const floorName: string = room.roomName.slice(2, 3);
          const roomName: string = room.roomName;
          const isSelectedCondo: boolean = condoName === selectedCondoName;
          const isSelectedFloor: boolean = floorName === selectedFloorName;
          const isSelectedRoom: boolean = roomName === selectedRoomName;

          let existAvailableCondo: any = _.find(tempAvailableCondoList, { name: condoName });
          if (isSelectedCondo) {
            if (existAvailableCondo) existAvailableCondo.status = 'selected';
            else tempAvailableCondoList.push({ name: condoName, status: 'selected' });
            let existAvailableFloor: any = _.find(tempAvailableFloorList, { name: floorName });
            if (isSelectedFloor) {
              if (existAvailableFloor) existAvailableFloor.status = 'selected';
              else tempAvailableFloorList.push({ name: floorName, status: 'selected' });
              if (isSelectedRoom) tempAvailableRoomList.push({ ...room, status: 'selected' });
              else tempAvailableRoomList.push({ ...room, status: 'available' });
            } else if (existAvailableFloor) existAvailableFloor.status = 'available';
              else tempAvailableFloorList.push({ name: floorName, status: 'available' });
          } else if (existAvailableCondo) existAvailableCondo.status = 'available';
            else tempAvailableCondoList.push({ name: condoName, status: 'available' });
          return room;
        });
        setAvailableCondoList(tempAvailableCondoList);
        setAvailableFloorList(tempAvailableFloorList);
        setAvailableRoomList(tempAvailableRoomList);
      }
    } catch (error: any) {
      setAvailableCondoList([]);
      setAvailableFloorList([]);
      setAvailableRoomList([]);
      setSelectedRoom({});
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeCondo = (tempSelectedCondo: condoInfoProps) => {
    // 이미 선택되어있는 동 이름
    const selectedCondoName = selectedRoom.roomName?.slice(0, 2);

    // 변경하려는 동이 이미 선택되어있는 동이라면 리턴
    if (tempSelectedCondo.status === 'selected' && tempSelectedCondo.name === selectedCondoName) return;

    // 사용 가능한 동 목록 재설정
    let tempAvailableCondoList = Array.from(availableCondoList);
    tempAvailableCondoList.map((condo: any) => {
      // 이전에 선택되어있던 동 상태는 available로, 새로 선택된 동 상태는 selected로 변경
      if (condo.name === selectedCondoName) condo.status = 'available';
      if (condo.name === tempSelectedCondo.name) condo.status = 'selected';
      return condo;
    });
    setAvailableCondoList(tempAvailableCondoList);

    // 선택된 객실 변경: 이미 선택된 동 안에 있는 객실 중, 첫번째 객실
    const tempSelectedRoom: any = _.find(totalRoomList, (room: roomInfoProps) => room.roomName?.slice(0, 2) === tempSelectedCondo.name);
    setSelectedRoom({ ...tempSelectedRoom, status: 'selected' });
    // 새로 선택된 객실의 동 이름, 층 이름, 객실 이름
    const newSelectedCondoName: string = tempSelectedRoom.roomName.slice(0, 2);
    const newSelectedFloorName: string = tempSelectedRoom.roomName.slice(2, 3);
    const newSelectedRoomName: string = tempSelectedRoom.roomName;

    // 사용 가능한 층, 객실 목록 재설정
    let tempAvailableFloorList: object[] = [];
    let tempAvailableRoomList: object[] = [];
    totalRoomList.map((room: any) => {
      const condoName: string = room.roomName.slice(0, 2);
      const floorName: string = room.roomName.slice(2, 3);
      const roomName: string = room.roomName;
      const isSelectedCondo: boolean = condoName === newSelectedCondoName;
      const isSelectedFloor: boolean = floorName === newSelectedFloorName;
      const isSelectedRoom: boolean = roomName === newSelectedRoomName;

      if (isSelectedCondo) {
        let existAvailableFloor: any = _.find(tempAvailableFloorList, { name: floorName });
        if (isSelectedFloor) {
          if (existAvailableFloor) existAvailableFloor.status = 'selected';
          else tempAvailableFloorList.push({ name: floorName, status: 'selected' });
          // 선택된 객실(위에서 변경된 객실) 상태는 selected로, 나머지 객실 상태는 available
          if (isSelectedRoom) tempAvailableRoomList.push({ ...room, status: 'selected' });
          else tempAvailableRoomList.push({ ...room, status: 'available' });
        } else if (existAvailableFloor) existAvailableFloor.status = 'available';
          else tempAvailableFloorList.push({ name: floorName, status: 'available' });
      }
      return room;
    });
    setAvailableFloorList(tempAvailableFloorList);
    setAvailableRoomList(tempAvailableRoomList);
  };

  const changeFloor = (tempSelectedFloor: floorInfoProps) => {
    // 이미 선택되어있는 동 이름과 층 이름
    const selectedCondoName = selectedRoom.roomName?.slice(0, 2);
    const selectedFloorName = selectedRoom.roomName?.slice(2, 3);

    // 변경하려는 층이 이미 선택되어있는 층이라면 리턴
    if (tempSelectedFloor.status === 'selected' && tempSelectedFloor.name === selectedFloorName) return;

    // 사용 가능한 층 목록 재설정
    let tempAvailableFloorList = Array.from(availableFloorList);
    tempAvailableFloorList.map((floor: any) => {
      // 이전에 선택되어있던 층 상태는 available로, 새로 선택된 층 상태는 selected로 변경
      if (floor.name === selectedFloorName) floor.status = 'available';
      if (floor.name === tempSelectedFloor.name) floor.status = 'selected';
      return floor;
    });
    setAvailableFloorList(tempAvailableFloorList);

    // 선택된 객실 변경: 이미 선택된 동과 새로 선택된 층 안에 있는 객실 중, 첫번째 객실
    const tempSelectedRoom: any = _.find(totalRoomList, (room: roomInfoProps) => room.roomName?.slice(0, 3) === `${selectedCondoName}${tempSelectedFloor.name}`);
    setSelectedRoom({ ...tempSelectedRoom, status: 'selected' });
    // 새로 선택된 층 이름, 객실 이름
    const newSelectedFloorName: string = tempSelectedRoom.roomName.slice(2, 3);
    const newSelectedRoomName: string = tempSelectedRoom.roomName;

    // 사용 가능한 객실 목록 재설정
    let tempAvailableRoomList: object[] = [];
    totalRoomList.map((room: any) => {
      const condoName: string = room.roomName.slice(0, 2);
      const floorName: string = room.roomName.slice(2, 3);
      const isSelectedCondoAndFloor: boolean = `${condoName}${floorName}` === `${selectedCondoName}${newSelectedFloorName}`;
      const isSelectedRoom: boolean = room.roomName === newSelectedRoomName;

      // 이미 선택된 동과 새로 선택된 층 안에 있는 객실이어야 하고
      if (isSelectedCondoAndFloor) {
        // 선택된 객실(위에서 변경된 객실) 상태는 selected로, 나머지 객실 상태는 available
        if (isSelectedRoom) tempAvailableRoomList.push({ ...room, status: 'selected' });
        else tempAvailableRoomList.push({ ...room, status: 'available' });
      }
      return room;
    });
    setAvailableRoomList(tempAvailableRoomList);
  };

  const changeRoom = (tempSelectedRoom: roomInfoProps) => {
    // 변경하려는 객실이 이미 선택되어있는 객실이라면 리턴
    if (tempSelectedRoom.status === 'selected' && tempSelectedRoom.roomName === selectedRoom.roomName) return;

    // 선택된 객실 변경
    setSelectedRoom({ ...tempSelectedRoom, status: 'selected' });

    // 사용 가능한 객실 목록 재설정
    let tempAvailableRoomList = Array.from(availableRoomList);
    tempAvailableRoomList.map((room: any) => {
      // 이전에 선택되어있던 객실 상태는 available로, 새로 선택된 객실 상태는 selected로 변경
      if (room.roomName === selectedRoom.roomName) room.status = 'available';
      if (room.roomName === tempSelectedRoom.roomName) room.status = 'selected';
      return room;
    });
    setAvailableRoomList(tempAvailableRoomList);
  };

  useEffect(() => {
    listRoom();
  }, [listRoom]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_room_list',
    });
  }, []);

  return (
    <>
      <MainLayout
        customStyle={{position: 'relative', margin: 0}}
        ContentBody={(
          <RoomList
            bookingItem={bookingItem}
            availableCondoList={availableCondoList}
            availableFloorList={availableFloorList}
            availableRoomList={availableRoomList}
            selectedRoom={selectedRoom}
            changeCondo={changeCondo}
            changeFloor={changeFloor}
            changeRoom={changeRoom}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default RoomListContainer;