import React from 'react';
import Modal from '../Modal';

interface PincodeMenualProps {
  isOpen: boolean,
  onClose: () => void,
};

const ModalPincodeMenual = ({
  isOpen,
  onClose,
}: PincodeMenualProps) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <img className='w-100' src='../../assets/images/how-to-pincode.jpg' alt='' />
            <button className='confirm-button h-40 mt-20' onClick={onClose}>
              확인
            </button>
          </>
        )}
      />
    </>
  );
};

export default ModalPincodeMenual;