import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as GoogleAnalytics from '../lib/google-analytics';
import MainLayout from '../components/layouts/MainLayout';
import KakaoAuth from '../components/views/KakaoAuth';
import ModalError from '../components/ModalError';
import { RootState } from '../stores';
import * as api from '../api/roomio';
import axios from 'axios';

const KakaoAuthContainer = () => {
  const { Kakao } = window as any;
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const navigation = useNavigate();
  const { bookingItem } = useSelector(({ booking }: RootState) => ({
    bookingItem: booking.bookingItem,
  }));
  
  const code = new URL(window.location.href).searchParams.get("code");

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const joinKakao =async (response:any) => {
    await api.joinKakao({ access_token: response.access_token });
  }

  const loginWithKakao = useCallback(() => {
    try {
      Kakao.Auth.authorize({
        redirectUri: 'https://eldorado-dev.h2ohospitality.io//auth',
      });
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithKakao2 = useCallback(async (code:string) => {
    try {
      await axios.post(
        'https://kapi.kakao.com/v2/user/me',
        {
          grant_type: 'authorization_code',
          client_id: process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY,
          redirectUri: 'https://eldorado-dev.h2ohospitality.io//auth',
          code: code,
        },{
          headers: {
            'Authorization': '',
            'Content-Type': 'application/x-www-form-urlencoded',
            'charset': 'utf-8',
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 카카오 챗봇으로 인해 필요 없어짐, 당분간 보류
  const getUserInfoWithKakao = useCallback(async (code: string) => {
    try {
      Kakao.API.request({
        headers: {
          Authorization: `Bearer ${code}`,
        },
        url: '/v2/user/me',
        success: function(response: any) {
          console.log('kakao get user response: ', response);
          if (bookingItem.reservationTelNo.startsWith('050')) navigation('/auth/success');
          if (!response || !response.kakao_account) {
            setModalErrorMessage('카카오로부터 정보를 가져오지 못 했습니다.');
            openModalError();
            return;
          }
          const splitedKaKaoPhoneNumber = response.kakao_account.phone_number.split('-');
          if (!splitedKaKaoPhoneNumber) {
            setModalErrorMessage('전화번호 형식 오류');
            setModalErrorSubMessage(`확인된 전화번호: ${response.kakao_account.phone_number}`);
            openModalError();
            return;
          }
          if (bookingItem.reservationTelNo.indexOf(`${splitedKaKaoPhoneNumber[1]}${splitedKaKaoPhoneNumber[2]}`) > 0) navigation('/auth/success');
          else if (bookingItem.reservationTelNo.indexOf(`${splitedKaKaoPhoneNumber[1]}-${splitedKaKaoPhoneNumber[2]}`) > 0) navigation('/auth/success');
          else {
            setModalErrorMessage('예약자 본인이 아닙니다.');
            setModalErrorSubMessage('예약하신 전화번호 확인 부탁드립니다.');
            openModalError();
            return;
          };
        },
        fail: function(error: any) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authKakao = useCallback(async () => {
    try {
      // if (isTestMode) navigation('/auth/success');
      // else loginWithKakao();
      loginWithKakao();
    } catch (error) {
      console.log(error);
    }
  }, [navigation, isTestMode, loginWithKakao]);

  useEffect(() => {
    if(code){
      loginWithKakao2(code);

    }
  },[code]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_kakao_auth',
    });
  }, []);

  return (
    <>
      <MainLayout
        ContentBody={(
          <KakaoAuth
            authKakao={authKakao}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </>
  );
};

export default KakaoAuthContainer;